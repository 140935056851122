<template>
  <v-card flat>
    <v-card-actions>
      <v-text-field
        class="ma-4"
        v-model="search"
        append-outer-icon="mdi-magnify"
        clear-icon="mdi-close-circle"
        :disabled="loading"
        :clearable="!isMobile"
        v-bind:label="$t('search')"
        single-line
        hide-details
        @click:append-outer="getItems"
        @click:clear="search = ''"
        @keyup.enter.native="getItems"
      />
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="itemsTotal"
        :loading="loading"
        :disable-sort="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="id"
        :hide-default-footer="itemsTotal <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        class="renderNewlines"
        dense
      >
        <template v-slot:item.pos_text="{ item }">
          <v-row no-gutters>
            <v-col cols="12">
              <span
                :class="{
                  'ml-n1': true,
                  'font-weight-bold': item.sub_component_id == null,
                  'font-weight-light': item.sub_component_id != null,
                }"
              >
                {{ item.pos_text }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="item.sub_pos_text" no-gutters>
            <v-col cols="12" class="ml-2">
              <span class="font-weight-thin ml-n1">
                {{ item.sub_pos_text }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.quantity="{ item }">
          <div
            :class="{
              'font-weight-light': item.sub_component_id != null,
            }"
          >
            {{ item.quantity }}
          </div>
        </template>

        <template v-slot:item.unit_price="{ item }">
          <div
            v-if="item.sub_component_id != null"
            :class="{
              'font-weight-light': item.sub_component_id != null,
            }"
          >
            {{ formatNumber(item.amount) }}
          </div>
        </template>

        <template v-slot:item.price="{ item }">
          <div
            :class="{
              'font-weight-light': item.sub_component_id != null,
            }"
          >
            {{ formatNumber(item.amount_without_vat) }}
          </div>
        </template>

        <template v-slot:item.discount="{ item }">
          <div
            v-if="item.discount != null && item.discount > 0"
            :class="{
              'font-weight-light': item.sub_component_id != null,
            }"
          >
            {{ formatNumber(item.discount) }}
          </div>
        </template>

        <template v-slot:item.vat="{ item }">
          <div
            v-if="item.sub_component_id == null"
            :class="{
              'font-weight-light': item.sub_component_id != null,
            }"
          >
            {{ formatNumber(item.vat_rate) }}%
          </div>
        </template>

        <template v-slot:item.amount="{ item }">
          <div
            v-if="item.sub_component_id == null"
            :class="{
              'font-weight-light': item.sub_component_id != null,
            }"
          >
            {{ formatNumber(item.amount_without_vat) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDate from "@/utils/mixins/formatDate";
import isMobile from "@/utils/mixins/isMobile";
import DataTableExtended from "@/components/basics/DataTableExtended.vue";

export default {
  name: "InvoiceComponents",
  mixins: [formatMoney, formatNumber, formatDate, isMobile],
  props: {
    invoiceId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    DataTableExtended,
  },
  data: () => ({
    items: [],
    loading: false,
    itemsPerPage: 50,
    itemsTotal: 0,
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 50,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
    search: "",
  }),
  watch: {
    invoiceId: {
      immediate: true,
      handler() {
        this.getItems();
      },
    },
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "$store.state.session": function () {
      this.search = "";
      this.getItems();
    },
    search() {
      var that = this;
      clearTimeout(that.delayTimer);
      that.delayTimer = setTimeout(function () {
        that.options.page = 1;
        that.getItems();
      }, 850);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("designation"),
          value: "pos_text",
          align: "start",
          sortable: false,
        },

        {
          text: this.$i18n.t("quantity"),
          value: "quantity",
          align: "end",
          sortable: false,
        },
        {
          text: "",
          value: "unit_price",
          align: "end",
          sortable: false,
        },
        {
          text: this.$i18n.t("price"),
          value: "price",
          align: "end",
          sortable: false,
        },
        {
          text: this.$i18n.t("discount"),
          value: "discount",
          align: "end",
          sortable: false,
        },
        {
          text: this.$i18n.t("VAT"),
          value: "vat",
          align: "end",
          sortable: false,
        },
        {
          text: this.$i18n.t("amount"),
          value: "amount",
          align: "end",
          sortable: false,
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  methods: {
    getItemClass(item) {
      return {
        "font-weight-light": item.sub_component_id != null,
      };
    },
    getParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      var params = {
        page_size: itemsPerPage,
        page_number: page - 1,
        pos_text: this.search || null,
      };
      return params;
    },
    getItems() {
      var that = this;
      var id = 0;
      this.loading = true;
      this.$http
        .get("invoices/" + this.invoiceId + "/components", {
          params: this.getParams(),
        })
        .then((response) => {
          that.items = response.data.results.map(function (item) {
            id = id + 1;
            return { id: id, ...item };
          });
          that.itemsTotal = response.data.count;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
   "designation": "Designation",
   "quantity": "Quantity",
   "price": "Price",
   "amount": "Amount",
   "discount": "Discount",
   "VAT": "VAT"
  },
  "de": {
   "designation": "Bezeichnung",
   "quantity": "Anzahl",
   "price": "Preis",
   "amount": "Betrag",
   "discount": "Rabatt",
   "VAT": "MwSt"
  }
}
</i18n>
