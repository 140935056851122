var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',[_c('v-text-field',{staticClass:"ma-4",attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","disabled":_vm.loading,"clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"click:append-outer":_vm.getItems,"click:clear":function($event){_vm.search = ''}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getItems.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"renderNewlines",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"disable-sort":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"id","hide-default-footer":_vm.itemsTotal <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      },"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.pos_text",fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:{
                'ml-n1': true,
                'font-weight-bold': item.sub_component_id == null,
                'font-weight-light': item.sub_component_id != null,
              }},[_vm._v(" "+_vm._s(item.pos_text)+" ")])])],1),(item.sub_pos_text)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-thin ml-n1"},[_vm._v(" "+_vm._s(item.sub_pos_text)+" ")])])],1):_vm._e()]}},{key:"item.quantity",fn:function({ item }){return [_c('div',{class:{
            'font-weight-light': item.sub_component_id != null,
          }},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.unit_price",fn:function({ item }){return [(item.sub_component_id != null)?_c('div',{class:{
            'font-weight-light': item.sub_component_id != null,
          }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" ")]):_vm._e()]}},{key:"item.price",fn:function({ item }){return [_c('div',{class:{
            'font-weight-light': item.sub_component_id != null,
          }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.amount_without_vat))+" ")])]}},{key:"item.discount",fn:function({ item }){return [(item.discount != null && item.discount > 0)?_c('div',{class:{
            'font-weight-light': item.sub_component_id != null,
          }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.discount))+" ")]):_vm._e()]}},{key:"item.vat",fn:function({ item }){return [(item.sub_component_id == null)?_c('div',{class:{
            'font-weight-light': item.sub_component_id != null,
          }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.vat_rate))+"% ")]):_vm._e()]}},{key:"item.amount",fn:function({ item }){return [(item.sub_component_id == null)?_c('div',{class:{
            'font-weight-light': item.sub_component_id != null,
          }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.amount_without_vat))+" ")]):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }