<template>
  <v-card :loading="loading">
    <v-toolbar color="titleBar" dark flat dense v-if="invoice">
      <v-toolbar-title>
        {{ $t("invoice") }} #{{ invoice.number }}
      </v-toolbar-title>
      <v-spacer />

      <v-tooltip v-if="invoice.pdf_available" top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="downloadFile('invoices/' + invoice.id + '/pdf')"
            text
            small
            icon
          >
            <v-icon>$PdfDownload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("show invoice PDF") }}</span>
      </v-tooltip>

      <v-tooltip v-if="!invoice.paid && showPaymentLink" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :href="invoice.url" target="_blank" text small icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pay invoice") }}</span>
      </v-tooltip>

      <wiki slug="invoice-details" />
    </v-toolbar>

    <v-card-text>
      <v-card outlined>
        <invoice-overview
          v-if="invoice != null"
          :invoice="invoice"
          class="my-2"
        />
      </v-card>
      <v-card class="mt-4" outlined>
        <invoice-components :invoice-id="invoiceId" class="my-2" />
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatMoney from "@/utils/mixins/formatMoney";
import downloadFile from "@/utils/mixins/downloadFile";
import InvoiceOverview from "@/components/invoices/InvoiceOverview";
import InvoiceComponents from "@/components/invoices/InvoiceComponents";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "InvoiceView",
  mixins: [formatDate, formatDateTime, formatMoney, downloadFile, isMobile],
  components: {
    InvoiceOverview,
    InvoiceComponents,
    Wiki,
  },
  props: {
    invoiceId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      invoice: null,
      loading: false,
    };
  },
  watch: {
    invoiceId: {
      immediate: true,
      handler() {
        this.loadInvoice();
      },
    },
  },
  computed: {
    showPaymentLink() {
      return this.$config.mandant == "iway";
    },
  },
  methods: {
    loadInvoice() {
      var that = this;
      if (this.invoiceId != null) {
        this.loading = true;
        this.$http
          .get("invoices/" + this.invoiceId)
          .then((response) => {
            this.invoice = response.data;
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "invoice": "Invoice",
    "show invoice PDF": "Show invoice PDF",
    "pay invoice": "pay invoice"
  },
  "de": {
    "invoice": "Rechnung",
    "show invoice PDF": "zeige Rechnungs PDF",
    "pay invoice": "bezahle Rechnung"
  }
}
</i18n>
